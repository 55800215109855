import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { InlineIcon } from '@iconify/react';
import stitcherIcon from '@iconify/icons-simple-icons/stitcher';
import { FaPodcast, FaSpotify, FaGooglePlay, FaRss } from 'react-icons/fa';

const iTunesColor = `rgb(131,65,194)`;
const spotifyColor = `rgb(85,175,93)`;
const googlePlayColor = `rgb(89,200,249)`;
const stitcherColor = `rgb(243,199,73)`;
const rssColor = `rgb(222,115,56)`;
function PodcastLinks() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            podcast {
              externalFeeds {
                apple
                spotify
                stitcher
                google
              }
              output
            }
          }
        }
      }
    `,
  );
  const podcast = site.siteMetadata.podcast;

  return (
    <ul style={{ display: `flex`, flexWrap: `wrap`, justifyContent: `space-between`, listStyle: `none`, padding: 0 }}>
      <li style={{ display: podcast.externalFeeds.apple ? 'list-item' : 'none' }}>
        <a
          href={podcast.externalFeeds.apple}
          rel="noopener noreferrer"
          target="_blank"
          style={{ boxShadow: `none`, color: iTunesColor, fontWeight: `bold` }}
        >
          <FaPodcast />
          <span style={{ margin: `0 5px` }}>iTunes</span>
        </a>
      </li>
      <li style={{ display: podcast.externalFeeds.spotify ? 'list-item' : 'none' }}>
        <a
          href={podcast.externalFeeds.spotify}
          rel="noopener noreferrer"
          target="_blank"
          style={{ boxShadow: `none`, color: spotifyColor, fontWeight: `bold` }}
        >
          <FaSpotify />
          <span style={{ margin: `0 5px` }}>Spotify</span>
        </a>
      </li>
      <li style={{ display: podcast.externalFeeds.stitcher ? 'list-item' : 'none' }}>
        <a
          href={podcast.externalFeeds.stitcher}
          rel="noopener noreferrer"
          target="_blank"
          style={{ boxShadow: `none`, color: stitcherColor, fontWeight: `bold` }}
        >
          <InlineIcon icon={stitcherIcon} />
          <span style={{ margin: `0 5px` }}>Stitcher</span>
        </a>
      </li>
      <li style={{ display: podcast.externalFeeds.google ? 'list-item' : 'none' }}>
        <a
          href={podcast.externalFeeds.google}
          rel="noopener noreferrer"
          target="_blank"
          style={{ boxShadow: `none`, color: googlePlayColor, fontWeight: `bold` }}
        >
          <FaGooglePlay />
          <span style={{ margin: `0 5px` }}>Google Play</span>
        </a>
      </li>

      <li>
        <a href="/rss.xml" style={{ boxShadow: `none`, color: rssColor, fontWeight: `bold` }}>
          <FaRss />
          <span style={{ margin: `0 5px` }}>RSS</span>
        </a>
      </li>
    </ul>
  );
}

export default PodcastLinks;
