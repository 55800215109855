import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

import { rhythm } from '../utils/typography';
import PodcastLinks from './podcastLinks';

function Layout({ isHome, children }) {
  const {
    site: {
      siteMetadata: { title, social },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            social {
              facebook
              twitter
              instagram
            }
          }
        }
      }
    `,
  );

  const HeaderTag = isHome ? `h1` : `h3`;

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>
        <div style={{ display: `flex`, justifyContent: `space-between` }}>
          <HeaderTag
            style={{
              fontFamily: `Montserrat, sans-serif`,
              marginTop: 0,
              fontSize: `2rem`,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </HeaderTag>
          <ul style={{ display: `flex`, justifyContent: `space-between`, listStyle: `none` }}>
            <li style={{ marginLeft: `10px`, display: social.facebook ? 'list-item' : 'none' }}>
              <a
                href={`https://www.facebook.com/${social.facebook}`}
                rel="noopener noreferrer"
                target="_blank"
                style={{ boxShadow: `none`, color: 'grey' }}
              >
                <FaFacebookF />
              </a>
            </li>
            <li style={{ marginLeft: `10px`, display: social.twitter ? 'list-item' : 'none' }}>
              <a
                href={`https://twitter.com/${social.twitter}`}
                rel="noopener noreferrer"
                target="_blank"
                style={{ boxShadow: `none`, color: 'grey' }}
              >
                <FaTwitter />
              </a>
            </li>
            <li style={{ marginLeft: `10px`, display: social.instagram ? 'list-item' : 'none' }}>
              <a
                href={`https://www.instagram.com/${social.instagram}`}
                rel="noopener noreferrer"
                target="_blank"
                style={{ boxShadow: `none`, color: 'grey' }}
              >
                <FaInstagram />
              </a>
            </li>
          </ul>
        </div>
        <PodcastLinks />
      </header>
      <main>{children}</main>
      <footer>
        <div>
          <Link to="/about">About</Link>
        </div>
        <div>
          © {new Date().getFullYear()}, {title}
        </div>
      </footer>
    </div>
  );
}

export default Layout;
